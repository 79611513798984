import { useState, useEffect } from 'react';
import { useField, useFormikContext } from 'formik';
import InputContainer from './parts/InputContainer';
import InputLabel from './parts/InputLabel';
import InputDescription from './parts/InputDescription';
import InputError from './parts/InputError';
import InputInfo from './parts/InputInfo';
import { HiPencil, HiCheck } from 'react-icons/hi';

const TextInput = ({
    label,
    description = '',
    info = '',
    inputRef = null,
    capitalize = false,
    className = '',
    previewEdit = false,
    onEditComplete = null,
    ...props
}) => {
    const [field, meta] = useField(props);
    const { setFieldValue } = useFormikContext();

    const [previewValue, setPreviewValue] = useState(field.value);
    const [isPreviewEditing, setIsPreviewEditing] = useState(false);

    useEffect(() => {
        if (previewEdit) {
            setPreviewValue(field.value);
        }
    }, [field.value, previewEdit]);

    const handlePreviewInputChange = () => {
        setIsPreviewEditing(false);

        setFieldValue(field.name, previewValue);

        onEditComplete && onEditComplete(previewValue);
    };

    return (
        <InputContainer className={className}>
            {label ? (
                <InputLabel htmlFor={props.id || props.name}>
                    {label}
                </InputLabel>
            ) : (
                ''
            )}

            {description ? (
                <InputDescription>{description}</InputDescription>
            ) : (
                ''
            )}
            <div
                className={
                    previewEdit && !isPreviewEditing
                        ? 'hidden'
                        : 'flex items-center space-x-2'
                }
            >
                <input
                    {...field}
                    {...props}
                    ref={inputRef}
                    className={`block w-full px-4 py-2 border bg-white border-gray-300 rounded focus:outline-none focus:ring-secondary focus:border-secondary text-base disabled:opacity-50 disabled:bg-lighter-gray disabled:cursor-not-allowed`}
                    value={previewEdit ? previewValue : field.value}
                    onChange={e => {
                        const value = e.target.value;

                        if (previewEdit) {
                            setPreviewValue(value);
                        } else {
                            setFieldValue(field.name, value);
                        }
                    }}
                    onKeyDown={e => {
                        if (previewEdit && e.code === 'Enter') {
                            e.preventDefault();
                            handlePreviewInputChange();
                        }
                    }}
                />
                {previewEdit && isPreviewEditing ? (
                    <button type="button" onClick={handlePreviewInputChange}>
                        <HiCheck className="w-4 h-4 fill-primary hover:fill-secondary transition" />
                    </button>
                ) : null}
            </div>
            {previewEdit && !isPreviewEditing ? (
                <div className="inline-flex items-center pl-4 py-3 space-x-2">
                    <span className="inline-block ">{field.value}</span>
                    {!props.disabled ? (
                        <button
                            type="button"
                            onClick={() => {
                                setIsPreviewEditing(true);
                            }}
                        >
                            <HiPencil className="w-4 h-4 fill-primary hover:fill-secondary transition" />
                        </button>
                    ) : null}
                </div>
            ) : null}

            {info && <InputInfo>{info}</InputInfo>}
            {meta.touched && meta.error ? (
                <InputError>{meta.error}</InputError>
            ) : null}
        </InputContainer>
    );
};

export default TextInput;
