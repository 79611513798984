import { Link } from 'react-router-dom';

function ButtonSimple({ children, className = '', to = '', href = '', ...props }) {
    const classNameStr = `inline-flex justify-center items-center text-secondary hover:text-secondary-hover p-0 text-underline transition outline-none ring-0 font-medium ${className}`;

    return to ? (
        <Link to={to} className={classNameStr} {...props}>
            {children}
        </Link>
    ) : href ? (
        <a href={href} className={classNameStr} {...props}>
            {children}
        </a>
    )
    :(
        <button className={classNameStr} {...props}>
            {children}
        </button>
    );
}

export default ButtonSimple;
