import { useEffect } from 'react';
import Logo from "../../assets/images/logo-alt.png";


function NotFound() {
    useEffect(() => {
        document.title = `Page not found`;
    }, []);

    return (
      <main className="grid min-h-screen place-items-center bg-white px-6 py-24 sm:py-32 lg:px-8">
        <div className="text-center">
          <div className="flex w-full items-center justify-center mb-10">
            <img className="w-32 lg:w-48" src={Logo} alt="Quickly Hire Logo" />
          </div>
          <p className="text-lg font-semibold text-red-500">404</p>
          <h1 className="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">
            Page not found
          </h1>
          <p className="mt-6 text-base leading-7 text-gray-600">
            Sorry, we couldn’t find the page you’re looking for.
          </p>
          <div className="mt-10 flex items-center justify-center gap-x-6">
            <a
              href="/"
              className="rounded-md bg-secondary px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              Go back home
            </a>
          </div>
        </div>
      </main>
    );
}

export default NotFound;
