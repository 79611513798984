import { ReactComponent as ProfileIcon } from '../assets/svg/icon-profile.svg';
import { ReactComponent as EngagementsIcon } from '../assets/svg/icon-engagements.svg';
import { ClockIcon } from '@heroicons/react/24/outline';

const proNav = [
    {
        title: 'Time Records',
        href: 'time-records',
        icon: ClockIcon,
    },
    {
        title: 'Engagements',
        href: 'engagements',
        icon: EngagementsIcon,
    },

    {
        title: 'My Profile',
        href: 'profile',
        icon: ProfileIcon,
    },
];

export default proNav;
