import { useField } from 'formik';
import InputContainer from './parts/InputContainer';
import InputError from './parts/InputError';
import { v4 as uuidv4 } from 'uuid';

export const CheckboxInput = ({ children, className = '', ...props }) => {
    // React treats radios and checkbox inputs differently other input types, select, and textarea.
    // Formik does this too! When you specify `type` to useField(), it will
    // return the correct bag of props for you -- a `checked` prop will be included
    // in `field` alongside `name`, `value`, `onChange`, and `onBlur`
    const [field, meta] = useField({ ...props, type: 'checkbox' });

    const checkboxId = uuidv4();

    return (
        <InputContainer className={className}>
            <input
                type="checkbox"
                id={checkboxId}
                {...field}
                {...props}
                className={`appearance-none h-5 w-5 border text-secondary focus:ring-secondary border-secondary rounded-sm bg-white checked:bg-secondary checked:border-secondary focus:outline-none transition duration-200 mt-px align-top bg-no-repeat bg-center bg-contain float-left mr-4 cursor-pointer}`}
            />
            <label htmlFor={checkboxId} className="leading-6 cursor-pointer">
                {' '}
                {children}
            </label>
            {meta.touched && meta.error ? (
                <InputError>{meta.error}</InputError>
            ) : null}
        </InputContainer>
    );
};

export default CheckboxInput;
