function ContentContainer({ children, className }) {
    return (
        <div
            className={`relative lg:border lg:border-solid lg:rounded-md lg:shadow-sm lg:border-light-gray max-w-5xl mx-auto ${className}`}
        >
            {children}
        </div>
    );
}

export default ContentContainer;
