import { ReactComponent as ProfileIcon } from '../assets/svg/icon-profile.svg';

const proNavPending = [
    {
        title: 'My Profile',
        href: 'profile',
        icon: ProfileIcon,
    },
];

export default proNavPending;
