import { useAuthStatus } from '../../context/AuthContext';

import { Fragment } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';

import Logo from '../../assets/images/logo-q.png';

import adminNav, { salesNav, recruiterNav } from '../../navigations/adminNav';
import managerNav from '../../navigations/managerNav';
import clientNav from '../../navigations/clientNav';
import proNav from '../../navigations/proNav';
import proNavPending from '../../navigations/proNavPending';

import { scrollbarClassesDark } from '../../inc/utils';

function Sidebar({ sidebarOpen, setSidebarOpen }) {
    const navigate = useNavigate();
    const { claims } = useAuthStatus();

    let navigation = [];

    if (claims.role === 'admin') navigation = adminNav;
    if (claims.role === 'sales') navigation = salesNav;
    if (claims.role === 'recruiter') navigation = recruiterNav;
    if (claims.role === 'manager') navigation = managerNav;
    if (claims.role === 'client') navigation = clientNav;
    if (claims.role === 'pro' && claims.status === 'pending')
        navigation = proNavPending;
    if (claims.role === 'pro' && claims.status === 'active')
        navigation = proNav;

    const openPage = href => {
        setSidebarOpen(false);
        navigate(href);
    };

    return (
        <div>
            <Transition.Root show={sidebarOpen} as={Fragment}>
                <Dialog
                    as="div"
                    className="relative z-40 md:hidden"
                    onClose={setSidebarOpen}
                >
                    <Transition.Child
                        as={Fragment}
                        enter="transition-opacity ease-linear duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="transition-opacity ease-linear duration-300"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-gray-600 bg-opacity-75" />
                    </Transition.Child>

                    <div className="fixed inset-0 z-40 flex">
                        <Transition.Child
                            as={Fragment}
                            enter="transition ease-in-out duration-300 transform"
                            enterFrom="-translate-x-full"
                            enterTo="translate-x-0"
                            leave="transition ease-in-out duration-300 transform"
                            leaveFrom="translate-x-0"
                            leaveTo="-translate-x-full"
                        >
                            <Dialog.Panel className="relative flex w-full max-w-xs flex-1 flex-col bg-primary pt-5 pb-4">
                                <Transition.Child
                                    as={Fragment}
                                    enter="ease-in-out duration-300"
                                    enterFrom="opacity-0"
                                    enterTo="opacity-100"
                                    leave="ease-in-out duration-300"
                                    leaveFrom="opacity-100"
                                    leaveTo="opacity-0"
                                >
                                    <div className="absolute top-0 right-0 -mr-12 pt-2">
                                        <button
                                            type="button"
                                            className="ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                                            onClick={() =>
                                                setSidebarOpen(false)
                                            }
                                        >
                                            <span className="sr-only">
                                                Close sidebar
                                            </span>
                                            <XMarkIcon
                                                className="h-6 w-6 text-white"
                                                aria-hidden="true"
                                            />
                                        </button>
                                    </div>
                                </Transition.Child>
                                <div className="flex flex-shrink-0 items-center px-4">
                                    <img
                                        className="h-10 w-10"
                                        src={Logo}
                                        alt="Quickly Hire Logo"
                                    />
                                </div>
                                <div className="mt-5 h-0 flex-1 overflow-y-auto">
                                    <nav className="space-y-1 px-2">
                                        {navigation.map(item => (
                                            <button
                                                type="button"
                                                key={item.title}
                                                onClick={() =>
                                                    openPage(item.href)
                                                }
                                                className="roup flex items-center px-2 py-2 text-base font-medium rounded-md text-indigo-100 hover:bg-primary-hover"
                                            >
                                                <item.icon
                                                    className={`mr-4 h-6 w-6 flex-shrink-0 text-indigo-300`}
                                                    aria-hidden="true"
                                                />
                                                {item.title}
                                            </button>
                                        ))}
                                    </nav>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                        <div className="w-14 flex-shrink-0" aria-hidden="true">
                            {/* Dummy element to force sidebar to shrink to fit close icon */}
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>

            {/* Static sidebar for desktop */}
            <div
                className={`hidden md:inset-y-0 md:flex md:w-40 md:h-full md:flex-col`}
            >
                {/* Sidebar component, swap this element with another sidebar if you like */}
                <div
                    className={`flex flex-grow flex-col overflow-y-auto bg-primary pt-5 ${scrollbarClassesDark}`}
                >
                    <div className="flex flex-shrink-0 justify-center items-center px-4 my-8">
                        <img
                            className="h-10 w-10"
                            src={Logo}
                            alt="Quickly Hire Logo"
                        />
                    </div>
                    <div className="mt-5 flex flex-1 flex-col">
                        <nav className="flex-1 space-y-3 pb-4">
                            {navigation.map(item => (
                                <NavLink
                                    key={item.title}
                                    to={`/${item.href}`}
                                    className={({ isActive }) =>
                                        `flex flex-col items-center px-2 py-2 text-sm font-normal border-r-4 border-solid  ${
                                            isActive
                                                ? 'border-secondary text-secondary'
                                                : 'border-transparent text-gray-100 hover:bg-primary-hover'
                                        }`
                                    }
                                >
                                    <item.icon
                                        className="h-6 w-6 flex-shrink-0 mb-1"
                                        aria-hidden="true"
                                    />
                                    {item.title}
                                </NavLink>
                            ))}
                        </nav>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Sidebar;
