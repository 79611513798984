import { initializeApp } from 'firebase/app';
import { getAuth, connectAuthEmulator } from 'firebase/auth';
import { getFirestore, connectFirestoreEmulator } from 'firebase/firestore';
import { getFunctions, connectFunctionsEmulator } from 'firebase/functions';
import { getStorage, connectStorageEmulator } from 'firebase/storage';
//import { getMessaging, getToken, onMessage } from "firebase/messaging";

const firebaseConfig = {
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_PROJECTID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_APP_ID,
    measurementId: process.env.REACT_APP_MEASUREMENT_ID,
};

const app = initializeApp(firebaseConfig);
// const messaging = getMessaging(app);

export const functions = getFunctions(app);
export const auth = getAuth();
export const db = getFirestore();
export const storage = getStorage();

// export const requestPermission = async () => {
//   console.log("Requesting User Permission......");
//   const permission = await Notification.requestPermission();
//   if (permission === "granted") {
//     console.log("Notification User Permission Granted.");
//     try {
//       const currentToken = await getToken(messaging, {
//         vapidKey: process.env.REACT_APP_VAPID_KEY,
//       });
//       if (currentToken) {
//         console.log("Client Token: ", currentToken);
//         return currentToken;
//       } else {
//         console.log("Failed to generate the app registration token.");
//       }
//     } catch (err) {
//       console.log(
//         "An error occurred when requesting to receive the token.",
//         err
//       );
//     }
//   } else {
//     console.log("User Permission Denied.");
//   }
// };

// export const onMessageListener = () =>
//   new Promise((resolve) => {
//     onMessage(messaging, (payload) => {
//       resolve(payload);
//     });
//   });

if (
    window.location.hostname === 'localhost' &&
    process.env.REACT_APP_DEPLOY_ENV === 'development'
) {
    connectAuthEmulator(auth, 'http://localhost:9099');
    connectFunctionsEmulator(functions, 'localhost', 5001);
    connectStorageEmulator(storage, 'localhost', 9199);
    connectFirestoreEmulator(db, 'localhost', 8081);
}
