import { Link } from 'react-router-dom';

function ButtonSimple({
    children,
    small = false,
    className = '',

    to = '',
    href = '',
    ...props
}) {
    const classNameStr = `inline-flex justify-center items-center p-0 outline-none ring-0 no-underline font-medium space-x-1 ${
        small
            ? 'text-gray-400 hover:text-gray-600 text-sm'
            : 'text-secondary hover:text-secondary-hover focus:text-secondary-hover'
    } ${className}`;

    return to ? (
        <Link to={to} className={classNameStr} {...props}>
            {children}
        </Link>
    ) : href ? (
        <a href={href} className={classNameStr} {...props}>
            {children}
        </a>
    ) : (
        <button className={classNameStr} {...props}>
            {children}
        </button>
    );
}

export default ButtonSimple;
