import { useState, useEffect } from 'react';
import { useField, useFormikContext } from 'formik';
import Select from 'react-select';
import InputContainer from './parts/InputContainer';
import InputLabel from './parts/InputLabel';
import InputDescription from './parts/InputDescription';
import InputError from './parts/InputError';
import InputInfo from './parts/InputInfo';

const Select2Input = ({
    label,
    description = '',
    info = '',
    multi = true,
    onChange = null,
    placeholder = '',
    className = '',
    ...props
}) => {
    const [field, meta] = useField(props);
    const { setFieldValue } = useFormikContext();
    const [defaultValue, setDefaultValue] = useState([]);

    const options = props?.children?.map(option => {
        const value = option.props.value;
        const label = option.props.children;
        return { value, label };
    });

    // Change default value label
    useEffect(() => {
        const defaultValue = props.value?.[0]?.value
            ? options.filter(
                  option =>
                      String(option.value) === String(props.value[0]?.value)
              )
            : '';

        setDefaultValue(defaultValue);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.value]);

    useEffect(() => {
        setValue(
            field.value && field.value.length
                ? field.value.map(
                      value =>
                          options.filter(
                              option =>
                                  String(option.value) === String(value.value)
                          )[0]
                  )
                : []
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [field.value]);

    const [value, setValue] = useState(defaultValue);
    const customSelectStyles = {
        control: (base, state) => ({
            ...base,
            boxShadow: state.isFocused ? '0 0 0 1px #43bee5' : 'none',
            borderColor: state.isFocused ? '#43bee5' : 'rgb(209, 213, 219)',
            '&:hover': {
                borderColor: 'rgb(209, 213, 219)',
            },
            backgroundColor: '#ffffff',
            borderRadius: '5px',
            padding: '0.5rem',
        }),
        indicatorSeparator: (base, state) => ({
            display: 'none',
        }),
        dropdownIndicator: (base, state) => {
            return {
                ...base,
                display: 'inline-flex',
                alignItems: 'center',
                padding: '0.5rem 0',
                color: 'rgb(107, 114, 128)',
                height: '1rem',
                width: '1rem',
            };
        },
        clearIndicator: (base, state) => {
            return {
                ...base,
                padding: 6,
            };
        },
        placeholder: (base, state) => ({
            ...base,
            fontSize: '16px',
        }),
        valueContainer: (base, state) => ({
            ...base,
            padding: '0 0.5rem',
        }),
        multiValue: (base, state) => {
            return {
                ...base,
                borderRadius: '2rem',
                backgroundColor: '#878CDB',
                margin: '0.2rem 0.5rem 0.2rem 0.2rem',
            };
        },
        multiValueLabel: (base, state) => {
            return {
                ...base,
                paddingLeft: '12px',
                color: '#ffffff',
            };
        },
        multiValueRemove: (base, state) => {
            return {
                ...base,
                color: '#ffffff',
                transition: 'all 150ms',
                '&:hover': {
                    backgroundColor: 'rgb(185, 28, 28)',
                    color: '#ffffff',
                    borderRadius: '0 2rem 2rem 0',
                },
            };
        },
        singleValue: (base, state) => ({
            ...base,
            fontSize: '16px',
        }),
        input: (base, state) => {
            return {
                ...base,
                lineHeight: '1',
                fontSize: '16px',
                margin: 0,
                padding: '0px',
                outline: 'none',
                outlineOffset: 0,
                '& input': {
                    boxShadow: 'none !important',
                },
            };
        },
        menu: (base, state) => {
            return {
                ...base,
                zIndex: '2',
            };
        },
    };

    return (
        <InputContainer className={className}>
            {label ? (
                <InputLabel htmlFor={props.id || props.name}>
                    {label}
                </InputLabel>
            ) : (
                ''
            )}

            {description ? (
                <InputDescription>{description}</InputDescription>
            ) : (
                ''
            )}
            <Select
                isMulti={multi}
                styles={customSelectStyles}
                {...field}
                options={options}
                value={value}
                defaultValue={defaultValue}
                placeholder={placeholder ? placeholder : 'Select...'}
                className="input-field input-field--select-2"
                onChange={values => {
                    const fValues =
                        values.constructor !== Array ? [values] : values;
                    setValue(fValues);
                    setFieldValue(field.name, fValues);
                    if (onChange) onChange(fValues);
                }}
            />
            {info && <InputInfo>{info}</InputInfo>}
            {meta.touched && meta.error ? (
                <InputError>{meta.error}</InputError>
            ) : null}
        </InputContainer>
    );
};

export default Select2Input;