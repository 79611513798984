import moment from 'moment';
import { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';

function NotificationItem({ notification, observer }) {
    const notificationItemRef = useRef(null);

    const time = moment(notification.timestamp).isBefore(
        moment().subtract(3, 'day')
    )
        ? moment(notification.timestamp).format('ddd, D  MMM')
        : moment(notification.timestamp).fromNow();

    useEffect(() => {
        if (!observer) return;
        observer.observe(notificationItemRef.current);
    }, [observer]);

    return (
        <Link
            to={notification.link}
            ref={notificationItemRef}
            data-id={notification.id}
            data-is-read={notification.isRead}
            className="notification-item | flex text-primary items-start py-2 pl-2 pr-4 bg-white cursor-pointer hover:bg-lighter-gray transition"
        >
            <div className="notification-item__read | pr-2 inline-flex relative top-1">
                <span
                    className={`inline-block w-2 h-2 bg-secondary rounded-full ${
                        notification.isRead ? 'opacity-0' : 'opacity-100'
                    } transition-opacity duration-1000`}
                ></span>
            </div>
            <div className="notification-item__content">
                <div
                    className="notification-item__text | text-xs"
                    dangerouslySetInnerHTML={{
                        __html: notification.text,
                    }}
                ></div>
                <div className="notification-item__time | text-xs text-gray-400">
                    {time}
                </div>
            </div>
        </Link>
    );
}

export default NotificationItem;
