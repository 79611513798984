import { ClassicSpinner } from 'react-spinners-kit';

const PageLoader = () => {
    return (
        <div className={'w-full h-full pt-20 flex justify-center items-center'}>
            <ClassicSpinner color={'#161b40'} size={40} loading={true} />
        </div>
    );
};

export default PageLoader;
