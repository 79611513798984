import { Suspense, lazy, useEffect } from 'react';
import {
    BrowserRouter as Router,
    Routes,
    Route,
    Outlet,
} from 'react-router-dom';
import './firebase.config';

//Intercom Wrapper
import IntercomWrapper from './components/IntercomWrapper/IntercomWrapper';

// Providers
import { GlobalProvider } from './context/GlobalContext';
import { AuthStatusProvider } from './context/AuthContext';
import { ModalProvider } from './core-ui/Modals';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { GAProvider } from './context/GAContext';

// Routes
import PrivateRoute from './routes/PrivateRoute';

// Toastify
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Page loader
import { PageLoader } from './core-ui/Loaders';

// Layout
import Dashboard from './components/Dashboard';
import NotFound from './pages/NotFound';

// Entry pages
import Home from './pages/Home';

//HubSpot Tracker
import {HubSpotTracker, HubSpotWrapper} from "./components/HubSpotTracker";

// Global pages
import Settings from './pages/Settings';
const ProPreview = lazy(() => import('./pages/ProPreview'));

// Public pages
const Apply = lazy(() => import('./pages/Apply/Apply'));
const Hire = lazy(() => import('./pages/Hire'));
const ScheduleCall = lazy(() => import('./pages/ScheduleCall'));

// New auth
const SignUp = lazy(() => import('./pages/Auth/SignUp'));
const VerificationSent = lazy(() => import('./pages/Auth/VerificationSent'));
const FinishSignUp = lazy(() => import('./pages/Auth/FinishSignUp'));
const FinishSetup = lazy(() => import('./pages/Auth/FinishSetup'));
const FinishSocialSignUp = lazy(() =>
    import('./pages/Auth/FinishSocialSignUp')
);
const SignIn = lazy(() => import('./pages/Auth/SignIn'));
const ForgotPassword = lazy(() => import('./pages/Auth/ForgotPassword'));
const ResetPassword = lazy(() => import('./pages/Auth/ResetPassword'));

// Helpers
const Helpers = lazy(() => import('./pages/Helpers'));

// Manager pages
const Overview = lazy(() => import('./pages/Admin/Overview'));
const Skills = lazy(() => import('./pages/Admin/Skills'));

const Professionals = lazy(() =>
    import('./pages/Admin/Professionals/Professionals1')
);
const Professional = lazy(() => import('./pages/Admin/Professional'));
const Engagements = lazy(() =>
    import('./pages/Admin/Engagements/Engagements1')
);
const NewEngagement = lazy(() => import('./pages/Admin/NewEngagement'));
const EngagementAdmin = lazy(() => import('./pages/Admin/Engagement'));
const AdminHelpers = lazy(() => import('./pages/Admin/Helpers'));
const Clients = lazy(() => import('./pages/Admin/Clients/Clients1'));
const SingleClient = lazy(() => import('./pages/Admin/SingleClient'));
const NewClient = lazy(() => import('./pages/Admin/NewClient'));
const NewProfessional = lazy(() => import('./pages/Admin/NewProfessional'));
const NewRequestAdminSide = lazy(() => import('./pages/Admin/NewRequest'));
const Managers = lazy(() => import('./pages/Admin/Managers'));
const NewManager = lazy(() => import('./pages/Admin/NewManager'));
const SingleManager = lazy(() => import('./pages/Admin/SingleManager'));
const Export = lazy(() => import('./pages/Admin/Export'));
const Positions = lazy(() => import('./pages/Admin/Positions'));
const Position = lazy(() => import('./pages/Admin/Position'));
const Packages = lazy(() => import('./pages/Admin/Packages'));
const Package = lazy(() => import('./pages/Admin/Package'));

// Pro pages
//const ProDashboard = lazy(() => import('./pages/Pro/Dashboard'));
const ProProfile = lazy(() => import('./pages/Pro/Profile'));
const ProEngagements = lazy(() => import('./pages/Pro/Engagements'));
const ProNotActive = lazy(() => import('./pages/Pro/NotActive'));
const ProNotifications = lazy(() => import('./pages/Pro/Notifications'));
const ProTimeTracking = lazy(() => import('./pages/Pro/TimeTracking'));
// const ProStatus = lazy(() => import('./pages/pro/ProStatus'));
// const ProProfile = lazy(() => import('./pages/pro/ProProfile'));
// const ProEngagements = lazy(() => import('./pages/pro/ProEngagements'));

// Client pages
const ClientDashboard = lazy(() => import('./pages/Client/Dashboard'));
const Team = lazy(() => import('./pages/Client/Team'));
const MSA = lazy(() => import('./pages/Client/MSA'));
//const ProPreview = lazy(() => import('./pages/Client/ProPreview'));
const Thankyou = lazy(() => import('./pages/Client/Thankyou'));
const NewRequest = lazy(() => import('./pages/Client/NewRequest'));
const PaymentMethods = lazy(() =>
    import('./pages/Client/PaymentMethods/PaymentMethods')
);
const NewPaymentMethod = lazy(() =>
    import('./pages/Client/PaymentMethods/NewPaymentMethod')
);
const Invoices = lazy(() => import('./pages/Client/Invoices'));

const ClientEngagement = lazy(() => import('./pages/Client/Engagement'));
const SetupAccount = lazy(() => import('./pages/Client/SetupAccount'));
const Checkout = lazy(() => import('./pages/Client/Checkout'));
const PaymentSuccess = lazy(() => import('./pages/Client/PaymentSuccess'));
const Refer = lazy(() => import('./pages/Client/Refer'));
const SoW = lazy(() => import('./pages/Client/Engagement/SoW'));

function App() {
    // useEffect(() => {
    //   if (process.env.REACT_APP_DEPLOY_ENV === "production") {
    //     const script = document.createElement("script");
    //     script.type = "text/javascript";
    //     script.id = "hs-script-loader";
    //     script.async = true;
    //     script.defer = true;
    //     script.src = "//js.hs-scripts.com/46060550.js";
    //     document.head.appendChild(script);
    //   }
    // }, []);

    return (
        <div className="App prose">
            <AuthStatusProvider>
                <HubSpotWrapper>
                    <ModalProvider>
                        <Router>
                            {process.env.REACT_APP_DEPLOY_ENV === 'production' && <HubSpotTracker /> }
                            <GlobalProvider>
                                <GAProvider>
                                    <Suspense fallback={<PageLoader />}>
                                        <Routes>
                                            <Route path="/" element={<Home />} />
                                            {/* Pro preview */}
                                            <Route
                                                path="pro/:id"
                                                element={<ProPreview />}
                                            />
                                            {/* User not logged in */}
                                            <Route
                                                element={
                                                    <PrivateRoute
                                                        shouldBeLoggedIn={false}
                                                        redirectTo=""
                                                    />
                                                }
                                            >
                                                {/* <Route
                                                    path="sign-up"
                                                    element={<SignUp />}
                                                /> */}
                                                <Route
                                                    path="verification-sent"
                                                    element={<VerificationSent />}
                                                />
                                                <Route
                                                    path="finish-sign-up"
                                                    element={<FinishSignUp />}
                                                />
                                                <Route
                                                    path="finish-social-sign-up"
                                                    element={<FinishSocialSignUp />}
                                                />
                                                <Route
                                                    path="sign-in"
                                                    element={<SignIn />}
                                                />
                                                <Route
                                                    path="login"
                                                    element={<SignIn />}
                                                />
                                                <Route
                                                    path="forgot-password"
                                                    element={<ForgotPassword />}
                                                />
                                                <Route
                                                    path="reset-password"
                                                    element={<ResetPassword />}
                                                />
                                                <Route
                                                    path="setup"
                                                    element={<SetupAccount />}
                                                />
                                                <Route
                                                    path="apply/:refId"
                                                    element={
                                                        <GoogleReCaptchaProvider
                                                            reCaptchaKey={
                                                                process.env
                                                                    .REACT_APP_RECAPTCHA_SITE_KEY
                                                            }
                                                        >
                                                            <Apply />
                                                        </GoogleReCaptchaProvider>
                                                    }
                                                />
                                                <Route
                                                    path="hire"
                                                    element={
                                                        <GoogleReCaptchaProvider
                                                            reCaptchaKey={
                                                                process.env
                                                                    .REACT_APP_RECAPTCHA_SITE_KEY
                                                            }
                                                        >
                                                            <Hire />
                                                        </GoogleReCaptchaProvider>
                                                    }
                                                />
                                                <Route
                                                    path="schedule-call"
                                                    element={
                                                        <GoogleReCaptchaProvider
                                                            reCaptchaKey={
                                                                process.env
                                                                    .REACT_APP_RECAPTCHA_SITE_KEY
                                                            }
                                                        >
                                                            <ScheduleCall />
                                                        </GoogleReCaptchaProvider>
                                                    }
                                                />

                                                {/* Remove helpers to admin later */}
                                                <Route
                                                    path="helpers"
                                                    element={<Helpers />}
                                                />
                                            </Route>
                                            {/* Clients setup */}
                                            <Route
                                                element={
                                                    <PrivateRoute
                                                        roles={['client']}
                                                        status={['setup']}
                                                    />
                                                }
                                            >
                                                <Route
                                                    path="finish-setup"
                                                    element={<FinishSetup />}
                                                />
                                            </Route>
                                            {/* Pro role + inactive status */}
                                            <Route
                                                element={
                                                    <PrivateRoute
                                                        roles={['pro']}
                                                        status={['inactive']}
                                                    />
                                                }
                                            >
                                                <Route
                                                    path="status"
                                                    element={<ProNotActive />}
                                                />
                                            </Route>
                                            {/* All roles */}
                                            <Route
                                                element={
                                                    <PrivateRoute
                                                        status={[
                                                            'active',
                                                            'pending',
                                                        ]}
                                                    />
                                                }
                                            >
                                                <Route element={<Dashboard />}>
                                                    <Route
                                                        element={
                                                            <IntercomWrapper />
                                                        }
                                                    >
                                                        <Route
                                                            path="settings"
                                                            element={<Settings />}
                                                        />

                                                        {/* Manager role */}
                                                        <Route
                                                            element={
                                                                <PrivateRoute
                                                                    roles={[
                                                                        'manager',
                                                                        'admin',
                                                                        'sales',
                                                                        'recruiter',
                                                                    ]}
                                                                />
                                                            }
                                                        >
                                                            <Route
                                                                path="d"
                                                                element={<Outlet />}
                                                            >
                                                                <Route
                                                                    path="overview"
                                                                    element={
                                                                        <Overview />
                                                                    }
                                                                />
                                                                <Route
                                                                    path="skills"
                                                                    element={
                                                                        <Skills />
                                                                    }
                                                                />
                                                                <Route
                                                                    path="positions"
                                                                    element={
                                                                        <Positions />
                                                                    }
                                                                />
                                                                <Route
                                                                    path="position/:id"
                                                                    element={
                                                                        <Position />
                                                                    }
                                                                />
                                                                <Route
                                                                    path="packages"
                                                                    element={
                                                                        <Packages />
                                                                    }
                                                                />
                                                                <Route
                                                                    path="package/:id"
                                                                    element={
                                                                        <Package />
                                                                    }
                                                                />
                                                                <Route
                                                                    path="engagements"
                                                                    element={
                                                                        <Engagements />
                                                                    }
                                                                />
                                                                <Route
                                                                    path="new-request"
                                                                    element={
                                                                        <NewRequestAdminSide />
                                                                    }
                                                                />
                                                                <Route
                                                                    path="new-engagement"
                                                                    element={
                                                                        <NewEngagement />
                                                                    }
                                                                />
                                                                <Route
                                                                    path="engagement/:id"
                                                                    element={
                                                                        <EngagementAdmin />
                                                                    }
                                                                />
                                                                <Route
                                                                    path="professionals"
                                                                    element={
                                                                        <Professionals />
                                                                    }
                                                                />

                                                                <Route
                                                                    path="professional/new"
                                                                    element={
                                                                        <NewProfessional />
                                                                    }
                                                                />
                                                                <Route
                                                                    path="professionals/edit/:proId"
                                                                    element={
                                                                        <Professional />
                                                                    }
                                                                />
                                                                <Route
                                                                    path="clients"
                                                                    element={
                                                                        <Clients />
                                                                    }
                                                                />
                                                                <Route
                                                                    path="clients/:clientId"
                                                                    element={
                                                                        <Clients />
                                                                    }
                                                                />
                                                                <Route
                                                                    path="client/new"
                                                                    element={
                                                                        <NewClient />
                                                                    }
                                                                />
                                                                <Route
                                                                    path="clients/edit/:clientId"
                                                                    element={
                                                                        <SingleClient />
                                                                    }
                                                                />
                                                                <Route
                                                                    path="managers"
                                                                    element={
                                                                        <Managers />
                                                                    }
                                                                />
                                                                <Route
                                                                    path="manager/new"
                                                                    element={
                                                                        <NewManager />
                                                                    }
                                                                />
                                                                <Route
                                                                    path="managers/edit/:id"
                                                                    element={
                                                                        <SingleManager />
                                                                    }
                                                                />
                                                                <Route
                                                                    path="helpers"
                                                                    element={
                                                                        <AdminHelpers />
                                                                    }
                                                                />
                                                                <Route
                                                                    path="export"
                                                                    element={
                                                                        <Export />
                                                                    }
                                                                />
                                                            </Route>
                                                        </Route>

                                                        {/* Client role */}
                                                        <Route
                                                            element={
                                                                <PrivateRoute
                                                                    roles={[
                                                                        'client',
                                                                    ]}
                                                                    status={[
                                                                        'active',
                                                                    ]}
                                                                />
                                                            }
                                                        >
                                                            <Route
                                                                path="thankyou"
                                                                element={
                                                                    <Thankyou />
                                                                }
                                                            />
                                                            <Route
                                                                path="dashboard"
                                                                element={
                                                                    <ClientDashboard />
                                                                }
                                                            />
                                                            <Route
                                                                path="team"
                                                                element={<Team />}
                                                            />
                                                            {/* <Route
                                                            path="team/:proId"
                                                            element={<ProPreview />}
                                                        /> */}
                                                            <Route
                                                                path="new-request"
                                                                element={
                                                                    <NewRequest />
                                                                }
                                                            />
                                                            <Route
                                                                path="invoices"
                                                                element={
                                                                    <Invoices />
                                                                }
                                                            />
                                                            <Route
                                                                path="payment-methods"
                                                                element={
                                                                    <PaymentMethods />
                                                                }
                                                            />
                                                            <Route
                                                                path="new-payment-method"
                                                                element={
                                                                    <NewPaymentMethod />
                                                                }
                                                            />
                                                            <Route
                                                                path="e/:id"
                                                                element={
                                                                    <ClientEngagement />
                                                                }
                                                            />
                                                            <Route
                                                                path="e/:id/sow"
                                                                element={<SoW />}
                                                            />
                                                            <Route
                                                                path="checkout/:id"
                                                                element={
                                                                    <Checkout />
                                                                }
                                                            />
                                                            <Route
                                                                path="payment-success/:id"
                                                                element={
                                                                    <PaymentSuccess />
                                                                }
                                                            />
                                                            <Route
                                                                path="refer"
                                                                element={<Refer />}
                                                            />
                                                            <Route
                                                                path="msa"
                                                                element={<MSA />}
                                                            />
                                                        </Route>
                                                        {/* Pro role + 'pending' and 'active' status */}
                                                        <Route
                                                            element={
                                                                <PrivateRoute
                                                                    roles={['pro']}
                                                                    status={[
                                                                        'active',
                                                                        'pending',
                                                                    ]}
                                                                />
                                                            }
                                                        >
                                                            <Route
                                                                path="engagements"
                                                                element={
                                                                    <ProEngagements />
                                                                }
                                                            />
                                                            <Route
                                                                path="pro"
                                                                element={
                                                                    <ProEngagements />
                                                                }
                                                            />
                                                            <Route
                                                                path="profile"
                                                                element={
                                                                    <ProProfile />
                                                                }
                                                            />
                                                            <Route
                                                                path="notifications"
                                                                element={
                                                                    <ProNotifications />
                                                                }
                                                            />
                                                            <Route
                                                                path="time-records"
                                                                element={
                                                                    <ProTimeTracking />
                                                                }
                                                            />
                                                        </Route>
                                                    </Route>
                                                </Route>
                                                {/* End of Dashboard wrapper component */}
                                            </Route>
                                            <Route
                                                path="*"
                                                element={<NotFound />}
                                            />
                                        </Routes>
                                    </Suspense>
                                </GAProvider>
                            </GlobalProvider>
                        </Router>
                    </ModalProvider>
                </HubSpotWrapper>
            </AuthStatusProvider>
            <ToastContainer
                position="top-right"
                closeOnClick
                pauseOnFocusLoss
                theme="light"
            />
        </div>
    );
}

export default App;
