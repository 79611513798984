import { useEffect, useState } from 'react';
import { Bars3BottomLeftIcon } from '@heroicons/react/24/outline';
import { useAuthStatus } from '../../context/AuthContext';
import { useGlobal } from '../../context/GlobalContext';
import { signOut } from 'firebase/auth';
import { auth } from '../../firebase.config';
import { Link } from 'react-router-dom';

import { toast } from 'react-toastify';
import { getCroppedImage, getErrorMessage } from '../../inc/helpers';

import BlankUserAvatar from '../../assets/images/blank-user-img.png';
import { BiUser } from 'react-icons/bi';

import Notifications from '../Notifications/Notifications';

const Header = ({ setMobileMenuOpen }) => {
    const { pageTitle, userAvatar, dispatch } = useGlobal();
    const [showSettings, setShowSettings] = useState(false);

    const { user } = useAuthStatus();

    useEffect(() => {
        dispatch({
            type: 'SET_USER_AVATAR',
            payload: user.photoURL,
        });
    }, [user, dispatch]);

    const handleWindowClick = e => {
        if (!e.target.closest('#header-settings')) setShowSettings(false);
    };

    useEffect(() => {
        document.addEventListener('click', handleWindowClick);

        return () => document.removeEventListener('click', handleWindowClick);
    }, []);

    const handleLogout = async () => {
        try {
            setShowSettings(false);
            await signOut(auth);
        } catch (error) {
            toast.error(getErrorMessage(error));
            console.log(error);
        }
    };

    return (
        <>
            <div className="relative top-0 z-10 flex h-16 md:h-28 flex-shrink-0 bg-white shadow-md md:shadow-none">
                <button
                    type="button"
                    className="border-r border-gray-200 px-4 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-500 md:hidden"
                    onClick={() => setMobileMenuOpen(true)}
                >
                    <span className="sr-only">Open sidebar</span>
                    <Bars3BottomLeftIcon
                        className="h-6 w-6"
                        aria-hidden="true"
                    />
                </button>
                <div className="flex-1 flex justify-between mx-4 sm:mx-16 border-none md:border-solid border-b border-gray-300">
                    <div className="flex-1 flex justify-between items-center">
                        <div className="flex-1 flex">
                            <h1
                                className={
                                    'hidden md:block font-medium text-sm sm:text-md md:text-xl lg:text-3xl text-gray-800 mb-0'
                                }
                            >
                                {pageTitle}
                            </h1>
                        </div>
                    </div>
                    <div className="header-actions | flex items-center space-x-4">
                        <Notifications />

                        <div
                            id="header-settings"
                            className="header-action | inline-flex relative"
                        >
                            <button
                                type="button"
                                className="relative inline-flex items-center justify-center h-10 w-10 rounded-full overflow-hidden bg-light-gray hover:bg-gray-300 transition"
                                onClick={() => {
                                    setShowSettings(prev => !prev);
                                }}
                            >
                                <span className="sr-only">Open user menu</span>
                                {userAvatar ? (
                                    <img
                                        className="absolute inset-0 w-full h-full object-cover"
                                        src={getCroppedImage(userAvatar)}
                                        alt="User avatar"
                                    />
                                ) : (
                                    <BiUser className="fill-primary w-[20px] h-[20px]" />
                                )}
                            </button>
                            <div
                                className={`absolute right-0 top-[calc(100%_+_5px)] z-10 w-48 rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 transition-all ${
                                    showSettings
                                        ? 'opacity-100 visible'
                                        : 'opacity-0 invisible'
                                }`}
                            >
                                <div className="px-4 py-4">
                                    <p className="text-sm my-0">Signed in as</p>
                                    <p className="text-sm font-medium text-secondary truncate my-0">
                                        {user?.email}
                                    </p>
                                </div>

                                <div>
                                    <Link
                                        to="/settings"
                                        className="block px-4 py-2 text-sm font-medium text-gray-700 hover:bg-lighter-gray transition"
                                        onClick={() => {
                                            setShowSettings(false);
                                        }}
                                    >
                                        Settings
                                    </Link>
                                </div>
                                <div>
                                    <button
                                        onClick={handleLogout}
                                        className="px-4 w-full flex items-start py-2 text-sm font-medium text-red-700 hover:bg-lighter-gray transition"
                                    >
                                        Sign out
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Header;
