import { useEffect } from 'react';
import { useAuthStatus } from '../../context/AuthContext';
import { useNavigate } from 'react-router-dom';

function Home() {
    const { claims, isLoggedIn, checkingStatus } = useAuthStatus();

    const navigate = useNavigate();

    useEffect(() => {
        if (!checkingStatus) {
            if (isLoggedIn && claims) {
                if (
                    claims.role &&
                    (claims.role === 'admin' ||
                        claims.role === 'manager' ||
                        claims.role === 'sales' ||
                        claims.role === 'recruiter')
                )
                    navigate('/d/overview', { replace: true });

                if (claims.role && claims.role === 'client') {
                    if (claims.status === 'setup')
                        navigate('/finish-setup', { replace: true });

                    if (claims.status === 'active')
                        navigate('/dashboard', { replace: true });
                }
                if (claims.role && claims.role === 'pro') {
                    // navigate('/engagements', { replace: true });
                    if (claims.status === 'active')
                        navigate('/time-records', { replace: true });
                    else if (claims.status === 'pending')
                        navigate('/profile', { replace: true });
                    else navigate('/status', { replace: true });
                }
            } else {
                navigate('/sign-in', { replace: true });
            }
        }
    }, [checkingStatus, isLoggedIn, claims, navigate]);

    return null;
}

export default Home;
