import { Navigate, Outlet } from 'react-router-dom';
import { useAuthStatus } from '../context/AuthContext';

function PrivateRoute({
    children,
    shouldBeLoggedIn = true,
    redirectTo = 'sign-in',
    roles,
    status,
}) {
    const { claims, isLoggedIn, checkingStatus } = useAuthStatus();

    if (checkingStatus) return null;

    if (
        // User should be logged in but isn't logged in
        (shouldBeLoggedIn && !isLoggedIn) ||
        // User should be logged out but is logged in
        (!shouldBeLoggedIn && isLoggedIn) ||
        // User doesn't have required role
        (roles && roles.length && !roles.includes(claims.role)) ||
        // User doesn't have required status
        (status && status.length && !status.includes(claims.status))
    ) {
        return <Navigate to={redirectTo} replace />;
    }

    return children ? children : <Outlet />;
}

export default PrivateRoute;
