import { useModal } from './Modal';

function ModalButton({ children, open, className, clickAction }) {
    const { setOpen } = useModal();

    return (
        <button
            type="button"
            data-open={open}
            className={`inline-flex justify-center items-center text-base text-white font-medium bg-secondary hover:bg-secondary-hover py-2 px-4 rounded-sm border-2 border-secondary hover:border-secondary-hover transition outline-none ring-0 ${className}`}
            onClick={e => {
                setOpen(e.target.dataset.open);
                clickAction && clickAction();
            }}
        >
            {children}
        </button>
    );
}

export default ModalButton;
