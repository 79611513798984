import { useField } from 'formik';
import InputContainer from './parts/InputContainer';
import InputLabel from './parts/InputLabel';
import InputDescription from './parts/InputDescription';
import InputError from './parts/InputError';
import InputInfo from './parts/InputInfo';

const SelectInput = ({
    label,
    description = '',
    info = '',
    className = '',
    ...props
}) => {
    const [field, meta] = useField(props);
    return (
        <InputContainer className={className}>
            {label ? (
                <InputLabel htmlFor={props.id || props.name}>
                    {label}
                </InputLabel>
            ) : (
                ''
            )}

            {description ? (
                <InputDescription>{description}</InputDescription>
            ) : (
                ''
            )}
            <select
                {...field}
                {...props}
                className={`block w-full px-4 py-2 border bg-white border-gray-300 rounded focus:outline-none focus:ring-secondary focus:border-secondary sm:text-base disabled:opacity-50 disabled:bg-lighter-gray disabled:cursor-not-allowed`}
            />
            {info && <InputInfo>{info}</InputInfo>}
            {meta.touched && meta.error ? (
                <InputError>{meta.error}</InputError>
            ) : null}
        </InputContainer>
    );
};

export default SelectInput;
