import { Link } from 'react-router-dom';

function ButtonOutline({ children, className = '', to, href, ...props }) {
    const classNameStr = `inline-flex justify-center items-center text-base text-secondary hover:text-white font-medium bg-transparent hover:bg-secondary py-2 px-4 rounded-lg border-2 border-secondary hover:border-secondary transition outline-none ring-0 ${className}`;

    if (href) {
        return (
            <a
                href={href}
                target="_blank"
                rel="noopener noreferrer"
                className={classNameStr}
            >
                {children}
            </a>
        );
    }

    if (to)
        return (
            <Link to={to} className={classNameStr} {...props}>
                {children}
            </Link>
        );

    return (
        <button className={classNameStr} {...props}>
            {children}
        </button>
    );
}

export default ButtonOutline;
