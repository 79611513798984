import { useState, useEffect } from "react";
import { useOutlet } from "react-router-dom";
import { useGlobal } from "../../context/GlobalContext";
import { useAuthStatus } from "../../context/AuthContext";
import Sidebar from "../Sidebar";
import Header from "../Header";
import { Tooltip as ReactTooltip } from "react-tooltip"

import { db } from "../../firebase.config";
import { doc, onSnapshot } from "firebase/firestore";

import { EnvelopeIcon } from "@heroicons/react/24/outline";
//import ProNotifications from "../../pages/Pro/Notifications/ProNotifications";

function Dashboard() {
  const outlet = useOutlet();
  const { pageTitle, dispatch } = useGlobal();
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [showSlideOver, setShowSlideOver] = useState(false);

  const { claims } = useAuthStatus();
  const isPro = claims?.role === "pro";

  useEffect(() => {
    const unsub = onSnapshot(doc(db, "stats", "counts"), (doc) => {
      dispatch({
        type: "SET_COUNTS",
        payload: doc.data(),
      });
    });

    return unsub;
  }, [dispatch]);

  return (
    <div className="relative h-screen flex overflow-hidden bg-white">
      {/* {
        //create chat bubble icon button at the bottom right of the screen
        //when clicked, it will open a slide over panel
        isPro && (
          <div className="fixed bottom-14 right-1 m-4 z-10">
            <button
              type="button"
              //title="Connect with managers"
              onClick={() => setShowSlideOver(true)}
              data-tooltip-content="Connect with managers"
              data-tooltip-id="connectWithManagers"
              className="p-2 rounded-full bg-secondary hover:bg-secondary-hover text-white hover:text-white focus:outline-none"
            >
              <span className="sr-only">Open Connect</span>
              <EnvelopeIcon
                className="h-9 w-9"
                aria-hidden="true"
              />
            </button>
            <ReactTooltip id="connectWithManagers" />
          </div>
        )
      } */}
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
      <div className="flex-1 flex flex-col h-screen overflow-hidden">
        <Header setMobileMenuOpen={setSidebarOpen} />
        <div className="w-full h-full px-8 pb-8 sm:px-12 sm:pb-12 md:px-16 md:pb-16 overflow-auto">
          <div className="pt-8 block md:hidden">
            <h1 className="font-medium text-2xl text-primary mb-0">
              {pageTitle}
            </h1>
          </div>
          <main className="py-8">{outlet}</main>
        </div>
      </div>
      {/* <ProNotifications open={showSlideOver} setOpen={setShowSlideOver} /> */}
    </div>
  );
}

export default Dashboard;
