import { useField, useFormikContext } from 'formik';
import InputContainer from './parts/InputContainer';
import InputError from './parts/InputError';
import { v4 as uuidv4 } from 'uuid';

export const RadioInput = ({ children, className = '', ...props }) => {
    const [field, meta] = useField({ ...props, type: 'checkbox' });
    const { values, setFieldValue } = useFormikContext();

    const radioId = uuidv4();
    return (
        <InputContainer className={className}>
            <div>
                <input
                    type="radio"
                    {...field}
                    {...props}
                    id={radioId}
                    checked={values[field.name] === field.value}
                    onChange={() => {
                        setFieldValue(field.name, field.value);
                    }}
                    className={`appearance-none h-5 w-5 border text-secondary focus:ring-secondary rounded-full border-secondary bg-white checked:bg-secondary focus:outline-none transition duration-200 mt-px align-top bg-no-repeat bg-center bg-contain float-left mr-4 cursor-pointer`}
                />
                <label htmlFor={radioId} className="leading-6 cursor-pointer">
                    {' '}
                    {children}
                </label>
            </div>
            {meta.touched && meta.error ? (
                <InputError>{meta.error}</InputError>
            ) : null}
        </InputContainer>
    );
};

export default RadioInput;
