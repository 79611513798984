import { ClassicSpinner } from 'react-spinners-kit';

const DataLoader = ({small = false}) => {
    return (
        <div>
            <ClassicSpinner color={'#161b40'} size={small? 20 : 40} loading={true} />
        </div>
    );
};

export default DataLoader;
