import {
    Squares2X2Icon,
    ClipboardDocumentListIcon,
    UserGroupIcon,
    IdentificationIcon,
    RectangleStackIcon,
    Cog8ToothIcon,
    HomeIcon,
    UsersIcon,
    InboxArrowDownIcon,
    BriefcaseIcon,
} from '@heroicons/react/24/outline';

export const adminNav = [
    {
        title: 'Overview',
        href: 'd/overview',
        icon: Squares2X2Icon,
    },
    {
        title: 'Engagements',
        href: 'd/engagements',
        icon: ClipboardDocumentListIcon,
    },
    {
        title: 'Professionals',
        href: 'd/professionals',
        icon: UserGroupIcon,
    },
    { title: 'Clients', href: 'd/clients', icon: UsersIcon },
    {
        title: 'Managers',
        href: 'd/managers',
        icon: IdentificationIcon,
    },
    {
        title: 'Packages',
        href: 'd/packages',
        icon: RectangleStackIcon,
    },
    { title: 'Positions', href: 'd/positions', icon: BriefcaseIcon },
    { title: 'Helpers', href: 'd/helpers', icon: Cog8ToothIcon },
    { title: 'Export', href: 'd/export', icon: InboxArrowDownIcon },
];

export const salesNav = [
    {
        title: 'Overview',
        href: 'd/overview',
        icon: Squares2X2Icon,
    },
    {
        title: 'Engagements',
        href: 'd/engagements',
        icon: ClipboardDocumentListIcon,
    },
    {
        title: 'Professionals',
        href: 'd/professionals',
        icon: UserGroupIcon,
    },
    { title: 'Clients', href: 'd/clients', icon: UsersIcon },
];

export const recruiterNav = [
    {
        title: 'Overview',
        href: 'd/overview',
        icon: Squares2X2Icon,
    },
    {
        title: 'Engagements',
        href: 'd/engagements',
        icon: ClipboardDocumentListIcon,
    },
    {
        title: 'Professionals',
        href: 'd/professionals',
        icon: UserGroupIcon,
    },
    { title: 'Clients', href: 'd/clients', icon: UsersIcon },
    { title: 'Export', href: 'd/export', icon: InboxArrowDownIcon },
];

export default adminNav;
