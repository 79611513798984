import { Link } from 'react-router-dom';

function Button({ children, className = '', to = '', ...props }) {
    const classNameStr = `inline-flex justify-center items-center text-base text-white disabled:text-white enabled:hover:text-secondary focus:text-secondary font-medium bg-secondary disabled:bg-secondary enabled:hover:bg-white focus:bg-white py-2 px-4 rounded-lg border-2 border-secondary disabled:border-secondary enabled:hover:border-secondary focus:border-secondary transition outline-none ring-0 ${className}`;

    return to ? (
        <Link to={to} className={classNameStr} {...props}>
            {children}
        </Link>
    ) : (
        <button className={classNameStr} {...props}>
            {children}
        </button>
    );
}

export default Button;
