import { db } from '../firebase.config';
import {
    getDocs,
    getDoc,
    doc,
    collection,
    query,
    orderBy,
    where,
} from 'firebase/firestore';
import moment from 'moment-timezone';

// Get error message
export const getErrorMessage = error => {
    if (error.code === 'auth/email-already-exists')
        return 'The provided email is already in use by an existing user';
    if (error.code === 'auth/email-already-in-use')
        return 'The provided email is already in use by an existing user';
    if (error.code === 'auth/invalid-email')
        return 'The provided email is invalid';
    if (error.code === 'auth/invalid-password')
        return 'The provided password is invalid';
    if (error.code === 'auth/invalid-phone-number')
        return 'The provided phone is invalid';
    if (error.code === 'auth/phone-number-already-exists')
        return 'The provided phone number is already in use by an existing user';
    if (error.code === 'auth/user-not-found') return 'Wrong email or password';
    if (error.code === 'auth/wrong-password') return 'Wrong email or password';
    if (error.code === 'auth/too-many-requests')
        return 'Access to this account has been temporarily disabled due to many failed login attempts. You can immediately restore it by resetting your password or you can try again later. ';
    if (error.code === 'auth/popup-closed-by-user')
        return 'Google Authentication cancelled';
    if (error.code === 'auth/requires-recent-login') return 'Problem with auth';

    if (error.code === 'storage/unknown') return 'An unknown error occurred.';
    if (error.code === 'storage/object-not-found')
        return 'No object exists at the desired reference.';
    if (error.code === 'storage/unauthenticated')
        return 'User is unauthenticated, please authenticate and try again';
    if (error.code === 'storage/unauthorized')
        return 'User is not authorized to perform the desired action, check your security rules to ensure they are correct.';
    if (error.code === 'storage/retry-limit-exceeded')
        return 'The maximum time limit on an operation (upload, download, delete, etc.) has been excceded. Try uploading again.';
    if (error.code === 'storage/canceled') return 'Operation cancelled.';
    if (error.code === 'storage/server-file-wrong-size')
        return 'File on the client does not match the size of the file recieved by the server. Try uploading again.';

    return error.message ? error.message : error;
};

export const numberToUsd = number =>
    new Intl.NumberFormat('us-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 2,
    }).format(Number(number));

export const numberToPercentage = number =>
    new Intl.NumberFormat('us-US', {
        style: 'percent',
        minimumFractionDigits: 2,
    }).format(Number(number / 100));

export const stringToHTML = str => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(str, 'text/html');
    return doc.body;
};

export const utcToDate = str => {
    if (!str) return '';

    const dateObject = moment.utc(str);
    return dateObject.format('MMM DD, YYYY');
};

export const replaceURLs = text => {
    // Find all texted links like <a href="https://google.com">Some text</a> and replace them with <a> tags

    // Get url regex, but exclude the <a> tags
    const urlRegex =
      /(?<!["'=<>])\b((?:https?|ftp):\/\/[^\s<>"']+(?!(?<=<p[^>]*?)["'][^<>]*?>)(?![^<>]*?>))/gi;
    const replacedText = text.replace(
        urlRegex,
        '<a href="$1" target="_blank" rel="noopener noreferrer">$1</a>'
    );

    // Find all <a> tags and add target="_blank" and rel="noopener noreferrer"
    const parser = new DOMParser();
    const doc = parser.parseFromString(replacedText, 'text/html');
    const links = doc.querySelectorAll('a');
    links.forEach(link => {
        link.setAttribute('target', '_blank');
        link.setAttribute('rel', 'noopener noreferrer');
    });

    // Return the updated text
    return doc.body.innerHTML;
};

/*
 * GET POSITION SKILLS
 */

export const getPositionSkills = async position => {
    try {
        const skillsSnap = await getDoc(
            doc(db, 'positions', position, 'data', 'skills')
        );

        const skills =
            skillsSnap.exists() && skillsSnap.data()
                ? Object.entries(skillsSnap.data())
                      .map(([id, item]) => ({
                          id,
                          ...item,
                      }))
                      .sort((a, b) => a.order - b.order)
                : [];

        return skills;
    } catch (error) {
        throw error;
    }
};

/**
 * GET POSITION SCOPES
 */

export const getPositionScopes = async position => {
    try {
        const scopesSnap = await getDoc(
            doc(db, 'positions', position, 'data', 'scopes')
        );

        const scopes =
            scopesSnap.exists() && scopesSnap.data()
                ? Object.entries(scopesSnap.data())
                      .map(([id, item]) => ({
                          id,
                          ...item,
                      }))
                      .sort((a, b) => a.order - b.order)
                : [];

        return scopes;
    } catch (error) {
        throw error;
    }
};

/*
 * GET ALL SKILLS
 */

export const getSkills = async () => {
    try {
        const positions = await getActivePositions();
        const skills = await Promise.all(
            positions.map(async position => {
                const skills = await getPositionSkills(position.id);
                return {
                    position: position.id,
                    items: Object.entries(skills)
                        .map(([id, item]) => ({
                            id,
                            ...item,
                        }))
                        .sort((a, b) => a.order - b.order),
                };
            })
        );

        return skills;
    } catch (error) {
        throw error;
    }
};

export const getValidatedSkills = async (providedIds, position = '') => {
    try {
        if (!providedIds) return [];
        // Get the list of all related skills
        const allSkills = await getPositionSkills(position);

        const skills = allSkills.filter(skill =>
            providedIds.includes(skill.id)
        );

        return skills;
    } catch (error) {
        throw error;
    }
};

/*
 * GET ALL SCOPES
 */

export const getScopes = async () => {
    try {
        const positions = await getActivePositions();
        const scopes = await Promise.all(
            positions.map(async position => {
                const scopes = await getPositionScopes(position.id);
                return {
                    position: position.id,
                    items: Object.entries(scopes)
                        .map(([id, item]) => ({
                            id,
                            ...item,
                        }))
                        .sort((a, b) => a.order - b.order),
                };
            })
        );

        return scopes;
    } catch (error) {
        throw error;
    }
};

/*
 * GET ALL POSITIONS
 */

export const getPositions = async () => {
    try {
        const positionsSnap = await getDocs(
            query(collection(db, 'positions'), orderBy('order', 'asc'))
        );

        const positions = [];

        positionsSnap.forEach(doc =>
            positions.push({
                id: doc.id,
                ...doc.data(),
            })
        );

        return positions;
    } catch (error) {
        throw error;
    }
};

/**
 * GET ACTIVE POSITIONS
 */

export const getActivePositions = async () => {
    try {
        const positionsSnap = await getDocs(
            query(
                collection(db, 'positions'),
                where('status', '==', 'active'),
                orderBy('order', 'asc')
            )
        );

        const positions = [];

        positionsSnap.forEach(doc =>
            positions.push({
                id: doc.id,
                ...doc.data(),
            })
        );

        return positions;
    } catch (error) {
        throw error;
    }
};

/*
 * GET ALL PACKAGES
 */

export const getPackages = async () => {
    try {
        const packagesSnap = await getDocs(
            query(collection(db, 'packages'), orderBy('order', 'asc'))
        );

        const packages = [];

        packagesSnap.forEach(doc =>
            packages.push({ id: doc.id, ...doc.data() })
        );

        return packages;
    } catch (error) {
        throw error;
    }
};

/*
 * GET PACKAGES WITH RATES
 */

export const getPackagesWithRates = async () => {
    const packagesSnap = await getDocs(collection(db, 'packages'));
    const packages = [];
    packagesSnap.forEach(doc => packages.push({ id: doc.id, ...doc.data() }));

    await Promise.all([
        ...packages.map(async (pkg, i) => {
            const ratesSnap = await getDocs(
                collection(db, 'packages', pkg.id, 'rates')
            );
            const rates = [];
            ratesSnap.forEach(doc => rates.push({ id: doc.id, ...doc.data() }));
            packages[i].rates = rates;
        }),
    ]);

    return packages;
};

/*
 * GET PRO RATES
 */

export const getProRates = async proId => {
    try {
        const [proSnap, proCustomRatesSnap, packages] = await Promise.all([
            getDoc(doc(db, 'users', proId)),
            getDocs(collection(db, 'users', proId, 'rates')),
            getPackagesWithRates(),
        ]);

        // Check if pro found
        if (!proSnap.exists()) return [];

        // Prepare custom rates
        const proCustomRates = [];
        proCustomRatesSnap.forEach(doc =>
            proCustomRates.push({ id: doc.id, ...doc.data() })
        );

        return packages;
    } catch (error) {
        throw error;
    }
};

/*
 * GET YEARS OF EXPERIENCE
 */

export const getYearsOfExperience = (
    year = new Date().getFullYear(),
    suffix
) => {
    const currentYear = new Date().getFullYear();
    const years = currentYear - year;

    if (suffix) {
        if (years === 1) return years + ' year';
        if (years > 1) return years + ' years';
    } else return years;
};

/*
 * GET DISPLAY NAME
 */
export const getDisplayName = user => {
    const firstName = user.firstName;
    const lastName = user.lastName;

    const arr = [];
    if (firstName) arr.push(firstName);
    if (lastName) arr.push(lastName);

    return arr.join(' ');
};

/*
 * GET ENGAGEMENT STATUS CLASS
 */
export const getEngagementStatusClass = status =>
    status === 'active'
        ? 'text-success'
        : status === 'suggested'
        ? 'text-secondary'
        : status === 'pending' ||
          status === 'pending_cancellation' ||
          status === 'requested'
        ? 'text-alert'
        : status === 'cancelled'
        ? 'text-warning'
        : '';

/**
 * GET CROPPED IMAGE
 */
// Crop
export const getCroppedImage = (url, crop = 80) => {
    if (!['production', 'staging'].includes(process.env.REACT_APP_DEPLOY_ENV))
        return url;

    const cropText = `${crop}x${crop}`;

    let newUrl = url;

    if (url.match(/photo\.\w+/)) {
        newUrl = newUrl.replace(/photo(.\w+)?/, 'photo.jpeg');
    }

    newUrl = newUrl.replace('personal%2Fphoto', `personal%2Fphoto_${cropText}`);

    return newUrl;
};

/*
 * GET DOMAIN
 */
export const getDomain = () => {
    if (process.env.REACT_APP_DEPLOY_ENV === 'development')
        return 'http://localhost:3000';
    if (process.env.REACT_APP_DEPLOY_ENV === 'staging')
        return 'https://quickly-hire-app.web.app';
    if (process.env.REACT_APP_DEPLOY_ENV === 'production')
        return 'https://app.quicklyhire.com';
};

/**
 * GET ENGAGEMENT DATE INFO
 */
export const getEngagementDate = engagement => {
    // If engagement is pending payment or activation
    if (
        engagement.status === 'pending' &&
        (engagement.pending === 'payment' ||
            engagement.pending === 'activation')
    )
        return {
            label: 'Start date',
            date: engagement.startDate,
        };

    // If engagement is requested, suggested or general pending
    if (['requested', 'suggested', 'pending'].includes(engagement.status))
        return {
            label: 'Request date',
            date: engagement.createdAt,
        };

    // If engagement is active
    if (['active'].includes(engagement.status))
        return {
            label: 'Start date',
            date: engagement.startedAt,
        };

    // If engagement is cancelled
    if (['cancelled'].includes(engagement.status))
        return {
            label: 'Cancel date',
            date: engagement.cancelledAt,
        };

    // If engagement is archived
    if (['archived'].includes(engagement.status))
        return {
            label: 'Archive date',
            date: engagement.archivedAt,
        };
};

/**
 * TRUNCATE TEXT
 */

export const truncateText = (text, length = 100) => {
    if (!text) return '';

    if (text.length <= length) return text;

    return text.slice(0, length) + '...';
};

/**
 * GET POSITION NAME FROM ID
 */

export const getPositionNameFromId = async id => {
    const positions = await getPositions();
    const position = positions.find(position => position.id === id);
    console.log({ position });
    return position ? position.title : '';
};

/**
 * GET ENTRY DURATION (SECONDS)
 */

export const getTimeEntryDuration = entry => {
    const startTime = entry?.startTime ? moment(entry?.startTime) : null;
    const endTime = entry?.endTime ? moment(entry?.endTime) : null;

    const totalDuration = moment.duration(endTime.diff(startTime)).asSeconds();

    // Break duration
    let breakTime = 0;

    if (entry.breaks && entry.breaks.length > 0) {
        entry.breaks.forEach((breakItem, i) => {
            const start = moment(breakItem.start);
            const end = moment(breakItem.end);

            if (
                !start.isAfter(startTime) ||
                !start.isBefore(endTime) ||
                !end.isAfter(startTime) ||
                !end.isBefore(endTime)
            )
                return;

            const duration = moment.duration(end.diff(start));
            const seconds = duration.asSeconds();

            breakTime += seconds;
        });
    }

    const duration = totalDuration - breakTime;

    return duration;
};

/**
 * GET ENTRY DURATION STRING
 */

export const getTimeEntryDurationString = entry => {
    const duration = getTimeEntryDuration(entry);

    const durationString = `${Math.floor(duration / 3600)} hrs ${Math.floor(
        (duration % 3600) / 60
    )} mins`;

    return durationString;
};

/**
 * GET WORKED HOURS STRING
 */
export const getWorkedHoursString = hrs => {
    // If hours less than 100, then return exact hours
    // If hours less than 1000, then need to display 100+, 200+, etc
    // If hours more than 1000, then need to display 1,000+, 2,000+, etc
    if (hrs < 100) return `${hrs}`;
    if (hrs < 1000) return `${Math.floor(hrs / 100) * 100}+`;
    return `${Math.floor(hrs / 1000)},000+`;
};

/**
 * INPUT CLASSES
 */
export const inputClasses = () =>
    `block w-full px-4 py-2 border bg-white border-gray-300 rounded focus:outline-none focus:ring-secondary focus:border-secondary text-base disabled:opacity-50 disabled:bg-lighter-gray disabled:cursor-not-allowed`;

/**
 * TIME HELPERS
 */
export const getLocalTime = (date = null) => {
    if (!date) date = new Date();

    return new Date(date.getTime() - date.getTimezoneOffset() * 60000)
        .toISOString()
        .slice(0, 16);
};

export const getLocalTimeWithOffset = ({
    minutes = 0,
    hours = 0,
    days = 0,
}) => {
    return new Date(
        new Date().getTime() + (minutes + hours * 60 + days * 24 * 60) * 60000
    )
        .toISOString()
        .slice(0, 16);
};

export const getUTCTime = date => {
    return new Date(date).toISOString();
};

export const renderDateTime = (date, showTimezone = false) => {
    return showTimezone
        ? moment(date).tz(moment.tz.guess()).format('DD MMM YYYY, hh:mm A (z)')
        : moment(date).format('DD MMM YYYY, hh:mm A');
};

export const utcToInput = str => {
    const date = new Date(str);
    return `${date.getFullYear()}-${
        date.getMonth() + 1 < 10
            ? `0${date.getMonth() + 1}`
            : date.getMonth() + 1
    }-${date.getDate() < 10 ? `0${date.getDate()}` : date.getDate()}T${date
        .getHours()
        .toString()
        .padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}`;
};
