import { useField } from 'formik';
import InputContainer from './parts/InputContainer';
import InputLabel from './parts/InputLabel';
import InputDescription from './parts/InputDescription';
import InputError from './parts/InputError';
import InputInfo from './parts/InputInfo';

export const TextareaInput = ({
    label,
    description = '',
    info = '',
    className = '',
    ...props
}) => {
    const [field, meta] = useField(props);
    return (
        <InputContainer className={className}>
            {label ? (
                <InputLabel htmlFor={props.id || props.name}>
                    {label}
                </InputLabel>
            ) : (
                ''
            )}
            {description ? (
                <InputDescription>{description}</InputDescription>
            ) : (
                ''
            )}
            <textarea
                {...field}
                {...props}
                className={`block w-full px-4 py-3 border bg-white border-gray-300 rounded focus:outline-none focus:ring-secondary focus:border-secondary text- disabled:opacity-50 disabled:bg-lighter-gray disabled:cursor-not-allowed min-h-[100px] ${
                    !label ? 'mt-3' : ''
                }`}
            >
                {meta.value}
            </textarea>
            {info ? (
                <InputInfo>
                    <i>{info}</i>
                </InputInfo>
            ) : (
                ''
            )}
            {meta.touched && meta.error ? (
                <InputError>{meta.error}</InputError>
            ) : null}
        </InputContainer>
    );
};

export default TextareaInput;
