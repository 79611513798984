import { ReactComponent as DashboardIcon } from '../assets/svg/icon-dashboard.svg';
import { ReactComponent as TeamIcon } from '../assets/svg/icon-team.svg';
import { ReactComponent as NewRequestIcon } from '../assets/svg/icon-new-request.svg';
import {
    DocumentCheckIcon,
    CurrencyDollarIcon,
} from '@heroicons/react/24/outline';
import { BanknotesIcon } from '@heroicons/react/24/outline';

const clientNav = [
    {
        title: 'Dashboard',
        href: 'dashboard',
        icon: DashboardIcon,
    },
    {
        title: 'Team',
        href: 'team',
        icon: TeamIcon,
    },
    {
        title: 'Request a Pro',
        href: 'new-request',
        icon: NewRequestIcon,
    },
    {
        title: 'Billing',
        href: 'invoices',
        icon: CurrencyDollarIcon,
    },
    {
        title: 'Refer & Earn',
        href: 'refer',
        icon: BanknotesIcon,
    },
];

export default clientNav;
